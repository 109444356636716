
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { createGlobalStyle } from 'styled-components';
import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const meta = {
    title: 'AnaSofia.pl',
    description: '',
    keywords: ''
};

export const titleTemplate = `%s | ${meta.title}`;

const GlobalStyles = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
    }

    html {
        overflow-x: hidden;
	    scroll-behavior: smooth;
    }

    body {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        overflow: hidden;
        scroll-behavior: smooth;
    }
`;

function MyApp({ Component, pageProps }: AppProps) {
    const { t } = useTranslation('seo');
    const { asPath } = useRouter();

    useEffect(() => {
        AOS.init({
            once: true,
            offset: 200
        });
    }, []);

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
                <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
                <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
                <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
            </Head>
            <DefaultSeo
                title={meta.title}
                description={t`description`}
                additionalMetaTags={[
                    {
                        name: 'keywords',
                        content: t`keywords`
                    },
                    {
                        name: 'author',
                        content: 'RECCLY Development studio@reccly.pl'
                    }
                ]}
                openGraph={{
                    type: 'website',
                    title: meta.title,
                    locale: 'pl_PL',
                    url: `https://${process.env.PUBLIC_URL}${asPath}`,
                    description: t`description`,
                    images: [
                        {
                            url: `https://${process.env.PUBLIC_URL}/logo_og.png`,
                            width: 1000,
                            height: 1000
                        }
                    ],
                    site_name: meta.title
                }}
            />
            <GlobalStyles />
            <Component {...pageProps} />
        </>
    );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  