module.exports = {
    locales: ['en', 'pl'],
    defaultLocale: 'en',
    pages: {
        '*': ['common', 'socialslider', 'navbar', 'footer', 'seo', 'carousel'],
        '/': [
            'home',
            'intro',
            'aboutme',
            'information',
            'portfolio',
            'achievements',
            'toplist',
            'contact',
            'contactform'
        ]
    }
};
